@font-face {
  font-family: 'Gotham';
  src: url('../../../fonts/GOTHAM.OTF'); /* IE9 Compat Modes */
  font-weight: 700;
  font-style: normal;
}

* {
  font-family: 'Gotham';
}


.Charts button{
  background-color: black;
}


/* Scrollbar */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(46, 46, 46, 0.8);
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);*/
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(59, 59, 59, 0.4);
}
/* Fim Scrollbar */

.Gauge {
  margin-top: 25px;
}

.label {
  color: #FEFEFE;
  float: left;
}
.icon {
  color: #FEFEFE;
  margin-right: 0px;
}

/* Buttons */
.chartButton {
  font-family: 'Gotham';
  background: black;
  transition: 0.3s;
  border-width: 2px;
  border-style: solid;
  border-radius: 20px;
  height: auto;
  width: -webkit-fill-available !important;
}

.tempButton,
.humButton {
  box-shadow: 0px 0px 4px 0.5px #6b81c7;
  border: rgb(170, 201, 241);
}

.ilumButton {
  box-shadow: 0px 0px 4px 0.5px #eeb89d;
  border: #fad4c1;
}

.meteringButton {
  box-shadow: 0px 0px 4px 0.5px rgb(102, 204, 102);
  border: rgb(175, 236, 175);
}

.intensityButton {
  box-shadow: 0px 0px 4px 0.5px #e0e052;
  border: #f0f0a6;
}

.smartLockButton {
  box-shadow: 0px 0px 4px 0.5px #e052bc;
  border: #e277d0;
}

.chaveUmQuartoButton {
  box-shadow: 0px 0px 4px 0.5px #e01f38;
  border: #dd5c5c;
}

.occupancyButton {
  box-shadow: 0px 0px 4px 0.5px #b352e0;
  border: #f0a6f0;
}

.pulseButton {
  box-shadow: 0px 0px 4px 0.5px #1b7964;
  border: #2d7e7e;
}

.sireneButton {
  box-shadow: 0px 0px 4px 0.5px #b6566b;
  border: #bd6d7e;
}

.closureButton {
  box-shadow: 0px 0px 4px 0.5px #c76b90;
  border: #e99ab9;
}

.waterButton {
  box-shadow: 0px 0px 4px 0.5px #6bb5c7;
  border: #90cfdf;
}

.smartPlugPlusButton {
  box-shadow: 0px 0px 4px 0.5px #4ef997;
  border: #94f7bf;
}

.smartSwitchButton {
  box-shadow: 0px 0px 4px 0.5px #61ac53;
  border: #a7e49c;
}

.ledButton {
  box-shadow: 0px 0px 4px 0.5px #fef784;
  border: #fafabd;
}

.ambiente {
  padding: 25px 20px 20px 20px;
  width: 97%;
  margin: -10px auto 15px auto;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 0px 2px 0.4px #faf4d9c0;
  opacity: 0;
  transform: translateY(-20px);
  animation: animeBottom 0.4s forwards;
}

@keyframes animeBottom {
  to {
    opacity: 1;
    transform: initial;
  }
}

.Collapsible {
  margin: 10px;
}

.Collapsible__trigger {
  border-radius: 10px;
  font-family: 'Gotham';
  background-color: #201E1E;
  opacity: 0.95;
  width: 98%;
  margin: 0px auto;
  color: #FEFEFE;
  display: block;
  position: relative;
  box-shadow: 0px 0px 2px 0.3px black;
  padding: 20px 20px 20px 20px;
  cursor: pointer;
  text-align: center;
  z-index: 2;
}

.Collapsible__trigger:hover {
  background: #2B2929;
  transition: 0.2s ease-in;
}

.Collapsible__trigger::after {
  content: '-';
  color: rgb(137, 194, 137);
  font-size: 15px;
  position: absolute;
  right: 21px;
  top: 19px;
  display: block;
  transition: ease-in 1s;
}

.open::after {
  content: 'x';
  color: rgb(197, 68, 74);
}

.div-temp {
  height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 10px;
  /*
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-image: url('../../../../src/bg.jpg');
  background-size: cover;
  */
}

.div-temp .title {
  font-family: 'Gotham';
  font-size: 1rem;
  color: rgb(221, 221, 221);
  margin-left: 1.5rem;
}

/* de 1800px até 2100px */
@media (min-width: 1800px) and (max-width: 2100px) {
  .Charts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
  }
}

/* de 2100px pra cima */
@media (min-width: 2100px) {
  .Charts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
  }
}
/* até 1800px */

@media (min-width: 1200px) and (max-width: 1800px) {
  .Charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
}

/* até 1200 px */

@media (min-width: 700px) and (max-width: 1200px) {
  .Charts {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
  }
  .div-devices {
    margin-right: 20px;
    /*
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-image: url('../../../../src/bg.jpg');
    background-size: cover;
    */
  }
  .ambiente {
    width: 95%;
  }
}

.ant-layout {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url('../../../../src/bg3.jpg');
  background-size: cover;
}

/* Até 700px */

@media (max-width: 700px) {
  .Charts {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px 0;
    margin: 15px auto;
  }
  .ant-layout {
    padding: 0 !important;
  }
  .div-devices {
    font-size: 0.7rem;
    margin: 0 2px 0 2px;
    /*
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-image: url('../../../../src/bg.jpg');
    background-size: cover;
    */
  }
  .ambiente {
    margin-top: -20px;
  }
  .collapsible::after {
    display: none;
  }
}

.div-devices { 
}