.hide{
    visibility: hidden;
    width: 40%;
    font-size: 14px;
    background-color: rgb(163, 162, 162);
    color: rgb(41, 41, 41);
    position: relative;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
.average:hover .hide{
visibility: visible;
}