@font-face {
  font-family: 'Gotham';
  src: url('../../../fonts/GOTHAM.OTF'); /* IE9 Compat Modes */
  font-weight: 700;
  font-style: normal;
}

.sider {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  color: #fff;
  background-color: #424040;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.5s all;
  height: 65px;
}

.sider:hover{
  background-color: rgb(116, 116, 116);
  transition: 0.5s all;
}


.dropdown-link {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  margin-top: auto;
  margin-bottom: auto;
}



.gtw {
  margin-right: 30px;
  margin-top: auto;
  margin-bottom: auto;
}
  .gtw1 {
  }
  .return-btn{
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    border-radius: 10px;
    transition: 0.5s all;
    background-color: #424040;
    min-height: 4rem;
    color: white;

}

.return-btn:hover{
    background: grey;
    transition: 0.5s all;
    background-color: rgb(116, 116, 116);
    
}


.user-avatar{
  margin-right: 20px;
  padding: 0px;
  margin-left: 5%;
  display: inline-block;
  vertical-align: middle;
}

.user-btn-text{
  font-size: 13px;
  border-radius: 5px;
  padding: 5px;
  color: white;
  display: inline-block;
  vertical-align: middle;
}

.gtw-btn{
  display: flex;
  flex-direction: row;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}