.navbar {
    transition: all 0.3s;
    background: #201E1E;
}

.navbar-animation{
    transition: all 0.3s;
    background: #201E1E;
}


.components{
    margin: 10px;
    background:  #201E1E;
    transition: 0.5s all;
    width: 70px;
}

.components-animation{

    margin: 10px;
    background:  #201E1E;
    transition: 0.5s all;
    width: 210px;
}

.nav-btn{
    border: none;
    transition: all 0.3s;
    cursor: pointer;
    margin-top: 30px;
    background: #201E1E;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    cursor:auto
}

.nav-btn:hover{
    transition: all 0.3s;
    background: #201E1E;
}

.user-btn{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    border-radius: 10px;
    transition: 0.5s all;
    padding: 10px 0px;
    min-height: 4rem;
    color: white;
}



.user-btn:hover{
    background: grey;
    transition: 0.5s all;
    background-color: rgb(116, 116, 116);
    
}

.return-btn{
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    border-radius: 10px;
    transition: 0.5s all;
    background-color: #424040;
    min-height: 4rem;
    color: white;

}

.return-btn:hover{
    background: grey;
    transition: 0.5s all;
    background-color: rgb(116, 116, 116);
    
}

.user-avatar{
    margin-right: 20px;
    padding: 0px;
    margin-left: 6%;
    display: inline-block;
    vertical-align: middle;
}

.user-btn-text{
    font-size: 13px;
    border-radius: 5px;
    padding: 5px;
    color: white;
    display: inline-block;
    vertical-align: middle;
    position: -webkit-sticky;
}


.hamburguer-logo{
    width: 220px
}

.hamburguer-xicon{
    width: 60px
}

.collapse-btn{
    border-radius: 5px;
    background-color: #424040;
    display: flex;
    margin-top: 10px;
    margin-left: 15px;
}


.collapse-btn:hover{
    background-color: rgb(116, 116, 116);
    border-radius: 5px;
}