body {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-image: url('../../../src/Teste.jpg');
  background-size: cover;
}

#logo {
  margin-top: 27px;
}


.main-container {
  padding: 3.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4%;
  opacity: 1.0;
  color: #201E1E;
}

.apps {
  display: flex;
  justufy-content: center;
  position: absolute;
  left: 17%;
  margin-top: 30px;
}

a {
  color: #FEFEFE;
}

.loginpage-loginbtn {
  border-radius: 5px !important;
  border: none;
  background-color: #B6D441 !important;
  height: 2.5rem;
  line-height: 1.375rem;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase !important;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 1.5rem;
  font-size: 0.875rem !important;
  letter-spacing: 0.5px !important;
  color: #201E1E;
}

/*.loginpage-loginbtn:hover {
  font-size: 1rem !important;
  font-weight: bold !important;
  background-color: #B6D441 !important;
  color: #201E1E;
}*/

/*
.loginpage-formlabel {
  color: #FEFEFE !important;
  font-size: 0.875rem;
  margin-bottom: 0%;
}*/

input.loginpage-input,
.loginpage-input > .ant-input {
  background-color: gray;
  color: #FEFEFE;
  font-size: 0.875rem;
  line-height: 1.375rem;
  border: none;
  border-radius: 12px;
  height: 2.5rem;
  width: 100% !important;
  margin-top: 0%;
  margin-bottom: 0px;
}

input.loginpage-inputpass,
.loginpage-inputpass > .ant-input {
  background-color: gray;
  color: #FEFEFE;
  font-size: 0.875rem;
  line-height: 1.375rem;
  border: none;
  height: 2.00rem;
  width: 100%;
  margin-top: 0%;
  margin-bottom: 0px;
}

.ant-input-affix-wrapper {
  border-radius: 12px !important;
  border: none;
  background-color: gray;
}

.loginpage-registerbtn {
  border-radius: 0 !important;
  border: 1px solid #52c41a !important;
  color: #52c41a;
  background-color: #fff !important;
  height: 40px !important;
  line-height: 1.375rem;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase !important;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0rem;
  font-size: 0.875rem !important;
  letter-spacing: 0.5px !important;
}

/*
.loginpage-registerbtn:focus,
.loginpage-registerbtn:hover {
  border: 1px solid #52c41a;
  color: #52c41a;
}
*/

.ant-card-body {
  width: 420px;
  height: 650px;
  background: #201E1E;
  border: none;
  border-radius: 30px;
  box-shadow: 0 0 1em black;
  /*
  position: relative;
  opacity: 0.8;
  */
  
}

.cardPrincipal {
  position: relative;
  opacity: 0.75;
}

/*.ant-card-body:hover {
  background: rgba(46, 46, 46, 0.85);
}*/

.loginpage-formlabel {
  color: #FEFEFE !important;
}

/*
@media (max-width: 700px) {
  .main-container {
    padding: 0;
  }

  .ant-card-bordered,
  .ant-card-body {
    max-width: 80vw;
  }

  input.loginpage-input,
  .loginpage-input > .ant-input {
    width: 12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .loginpage-loginbtn {
    font-size: 0.6rem !important;
  }
}
*/

#appstore {
  margin-right: 30px;
}

#googleplay {
  margin-left: 10px;
}

.conta {
  text-direction: column;
  text-align: center;
  color: #FEFEFE;
}

a:hover {
  color: gray;
}

/* checkbox-circle */
.checkbox-circle input[type="checkbox"] {
  position: relative;
  display: none;
  margin-top: 0px;
}
.checkbox-circle input[type="checkbox"] + label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Gotham';;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.checkbox-circle input[type="checkbox"]:hover + label:hover {
  color:  gray;
  margin-top: 0px;
}
.checkbox-circle input[type="checkbox"]:hover + label:before {
  border: 1px solid #343a3f;
  width: 1.2em;
  height: 1.2em;
  border: 2px solid #fff;
  background: #50565a;
  box-shadow: 0 0 0 1px #000;
}
.checkbox-circle input[type="checkbox"] + label:last-child {
  margin-bottom: 0%;
  margin-top: 0%;
}
.checkbox-circle input[type="checkbox"] + label:before {
  content: "";
  display: block;
  width: 1.4em;
  height: 1.4em;
  border: 1px solid #343a3f;
  border-radius: 1em;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.2s, transform 0.3s ease-in-out;
  transition: all 0.2s, transform 0.3s ease-in-out;
  background:  #FEFEFE;
}
.checkbox-circle input[type="checkbox"]:checked + label:before {
  border-radius: 1em;
  border: 2px solid #fff;
  width: 1.2em;
  height: 1.2em;
  background: #50565a;
  box-shadow: 0 0 0 1px #000;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
/* checkbox-circle end */

.item {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  /* minmax(300px, 1fr)*/
  grid-gap: 30px;
  padding: 20px;
  justify-items: center;
  justify-content: center;
  align-items: start;
}

#fonte {
  color: white;
}
