@font-face {
  font-family: 'Gotham';
  src: url('../../../fonts/GOTHAM.OTF'); /* IE9 Compat Modes */
  font-weight: 700;
  font-style: normal;
}

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 5px 3rem 5px 3rem;
  font-family: 'Gotham';
  background: transparent;
}

.logo {
  width: 155px;
  margin-top: 20px;
}

.User {
  display: inline-block;
}

.ButtonUser {
  min-height: 4rem;
  color: white;
}

.ButtonUser span {
  color: white;
}

@media (max-width: 900px) {
  .logo {
    width: 120px;
  }
}

@media (max-width: 700px) {
  .Header {
    padding: 11px 1rem 11px 2rem;
  }
}
