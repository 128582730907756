@font-face {
    font-family: 'Gotham';
    src: url('../../../fonts/GOTHAM.OTF'); /* IE9 Compat Modes */
     font-weight: 700;
     font-style: normal;
   }
*{
    font-family: 'Gotham';
}
.environmentPage{
    height: 90vh;
    overflow-y: scroll;
    overflow-x:hidden;
}
.environmentName{
    font-size: 1rem;
    color: #FEFEFE;
    margin-left: 1.5rem;
}
.healthy-container .ant-row{
   justify-content: center;
}

.healthy-container{
    width:95%;
    background: #272d33;
    border-radius: 20px;
    padding: 1rem 2rem;
    margin: 1rem 1.5rem;
}

.ant-alert{
    display: flex;
    position: fixed;
    z-index: 99;
    margin: 1rem auto;
    width: 90%;
    background: #202428 !important;
    border-radius: 20px;
    border:solid 1px #707070;
    
}
.ant-alert-message{
    margin: 1rem 2rem;
    color:#c0c0c0;
}
.categories-container{
    width:95%;
    margin-left: 1.5rem;
    background: #272d33;
    border-radius: 20px;
    padding: 1rem 2rem;
}

.ant-tabs{
    color: #FEFEFE;
    font-family: 'Gotham';
}
.ant-tabs-tab:hover{
    color: #FEFEFE;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #FEFEFE;
}
.ant-tabs-ink-bar{
    background: #FEFEFE;
}

.noDevice{
    height: 30vh;
    display: grid;
    justify-items: center;
    align-items: center;
    color: #969696;
}
.noDevice, .span{
    font-size: 1.2rem;
}

.charts{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 1200px){
    .charts{
        display: grid;
        grid-template-columns: 1fr ;
    }
}

#loading{
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%); 
}

.ant-notification{
    bottom:0;
    left: 0;
    right: 0;
    position: absolute;
    margin: 0 auto 0 32vh !important;
    width: 75%;
}
.ant-notification-notice{
    background: rgba(62, 68, 73, 0.7) !important;
    border-radius: 20px;
    border:solid 1px #707070;
    width: 100%;
    color: white;
}

.ant-notification-notice-message{
    color:white;
}
.ant-notification-close-x{
    color: white;
}